<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-gavel</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Bes. rechtliche Lage</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2 mt-4"
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        persistent-hint
        hint="Schüler*in oder Klasse"
        clearable
      ></v-text-field>
      <template v-slot:extension>
        <DivisionTabs align-with-title v-model="filter.division" all />
      </template>
    </v-app-bar>
    <v-toolbar dense flat class="mb-4">
      <LookupValueInput
        single-line
        hide-details
        v-model="filter.enrollmentStatus"
        :items="enrollmentStatuses"
        label="Anmeldestatus"
        class="mx-1"
        clearable
      />
      <LookupValueInput
        single-line
        hide-details
        v-model="filter.legalStatus"
        :items="legalStatuses"
        label="rechtlige Lage"
        class="mx-1"
        clearable
      />
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="itemsFiltered"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small hideExt :value="item.student" />
        </template>
        <template v-slot:item.enrollmentStatus="{ item }">
          <LookupValue :value="item.enrollmentStatus" />
        </template>
        <template v-slot:item.legalStatus="{ item }">
          <LookupValue :value="item.legalStatus" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import DivisionTabs from "@/components/DivisionTabs.vue";
import LookupValue from "common/components/LookupValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem.vue";

import { comparePeople } from "common/utils/people.js";
import { compareLookups } from "common/utils/helper.js";

export default defineComponent({
  name: "SpecialStatus",
  components: {
    DivisionTabs,
    LookupValue,
    LookupValueInput,
    PersonItem,
  },
  data() {
    return {
      filter: {
        division: { id: 0 },
        search: "",
        enrollmentStatus: null,
        legalStatus: null,
      },
      loading: false,
      headers: [
        { text: "Klasse", value: "student.schoolClass.code" },
        { text: "Schüler*in", value: "student", sort: comparePeople },
        {
          text: "Anmeldestatus",
          value: "enrollmentStatus",
          sort: compareLookups,
        },
        { text: "rechtliche Lage", value: "legalStatus", sort: compareLookups },
      ],
      items: [],
    };
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        localStorage.setItem(
          "specialStatusFilter",
          JSON.stringify(this.filter)
        );
      },
    },
  },
  async created() {
    if (localStorage.getItem("specialStatusFilter")) {
      this.filter = JSON.parse(localStorage.getItem("specialStatusFilter"));
    }
    this.loading = true;
    this.items = await this.apiList({
      resource: "student/specialstatus",
    });

    this.loading = false;
  },
  computed: {
    enrollmentStatuses() {
      return [
        ...new Set(this.itemsDivision.map((el) => el.enrollmentStatus)),
      ].sort();
    },
    legalStatuses() {
      return [
        ...new Set(this.itemsDivision.map((el) => el.legalStatus)),
      ].sort();
    },
    itemsDivision() {
      if (!this.filter.division) {
        return this.items;
      }
      return this.items.filter(
        (item) =>
          this.filter.division == null ||
          this.filter.division.id === 0 ||
          (item.student.division != null &&
            this.filter.division.id === item.student.division.id)
      );
    },
    itemsFiltered() {
      if (!this.filter.division) {
        return this.items;
      }
      return this.itemsDivision.filter(
        (item) =>
          (!this.filter.enrollmentStatus ||
            this.filter.enrollmentStatus.id === item.enrollmentStatus.id) &&
          (!this.filter.legalStatus ||
            this.filter.legalStatus.id === item.legalStatus.id) &&
          (!this.filter.search ||
            item.student.firstName
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()) ||
            item.student.lastName
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()) ||
            item.student.schoolClass.code
              .toLowerCase()
              .includes(this.filter.search.toLowerCase()))
      );
    },
  },
});
</script>
